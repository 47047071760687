<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver
              v-if="business"
              v-slot="{ handleSubmit, invalid, touched }"
              ref="businessForm"
            >
              <form
                class="authentication-form"
                @submit.prevent="handleSubmit(createBusiness)"
              >
                <div class="row">
                  <div class="col-md-6">
                    <TextInput
                      v-model="business.first_name"
                      name="first_name"
                      rules="required"
                      label="First Name"
                    />
                  </div>
                  <div class="col-md-6">
                    <TextInput
                      v-model="business.last_name"
                      name="last_name"
                      rules="required"
                      label="Last Name"
                    />
                  </div>
                  <div class="col-md-6">
                    <TextInput
                      v-model="business.name"
                      name="name"
                      rules="required"
                      label="Company Name"
                    />
                  </div>
                  <div class="col-md-6">
                    <TextInput
                      v-model="business.email"
                      name="email"
                      rules="required|email"
                      label="Email"
                    />
                  </div>
                  <div class="col-md-6">
                    <PhoneInput
                      v-model="business.phone_number"
                      name="phone"
                      rules="required|phone"
                      label="Phone Number"
                    />
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Currency</label>
                      <select v-model="business.currency" class="form-control">
                        <option
                          v-for="currency in currencies"
                          :key="currency.name"
                          :value="currency.name"
                          >{{ currency.label }}</option
                        >
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Date format</label>
                      <select v-model="business.date_format" class="form-control">
                        <option
                          v-for="datetime in dateFormats"
                          :key="datetime"
                          :value="datetime"
                          >{{ datetime }}</option
                        >
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <SelectInput
                      v-model="business.timezone"
                      :options="timezones"
                      label="Timezone"
                    />
                  </div>
                  <div class="col-md-6">
                    <label>Message Limit</label>
                    <div class="mb-2 d-flex">
                      <div class="pt-2">
                        <b-form-checkbox
                          v-model="business.message_limit"
                          name="check-button"
                          switch
                          inline
                        >
                        </b-form-checkbox>
                      </div>
                      <div class="flex-fill">
                        <TextInput
                          v-model="business.no_messages"
                          :readonly="!business.message_limit"
                          type="number"
                          name="no_messages"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label>Contact Limit</label>
                    <div class="mb-2 d-flex">
                      <div class="pt-2">
                        <b-form-checkbox
                          v-model="business.contact_limit"
                          name="check-button"
                          switch
                          inline
                        >
                        </b-form-checkbox>
                      </div>
                      <div class="flex-fill">
                        <TextInput
                          v-model="business.no_contacts"
                          :readonly="!business.contact_limit"
                          type="number"
                          name="no_contacts"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-4 mt-2">
                    <div class="mt-2 mb-2 pt-2">
                      <span class="pr-2" style="font-weight:600;">OFF</span>
                      <b-form-checkbox
                        v-model="business.send_registration_email"
                        name="check-button"
                        switch
                        inline
                      >
                        ON - Send registration email
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <b-form-group>
                  <b-button
                    variant="primary"
                    :disabled="loadingCreate || (invalid && touched)"
                    type="submit"
                  >
                    <b-spinner v-if="loadingCreate" small />
                    <span v-else>Create</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'

export default {
  data() {
    return {
      business: {
        name: '',
        email: '',
        send_registration_email: true,
        date_format: 'MM/DD/YYYY',
        country: 'US',
        currency: 'Dollar',
        timezone: moment.tz.guess(),
      },
      loadingCreate: false,
    }
  },

  computed: {
    timezones() {
      return this.$store.getters['app/timezones']
    },

    currencies() {
      return this.$store.getters['app/currencies']
    },

    dateFormats() {
      return this.$store.getters['app/dateFormats']
    },
  },

  methods: {
    createBusiness() {
      this.loadingCreate = true

      this.$store
        .dispatch('business/create', this.business)
        .then(() => {
          this.loadingCreate = false
          this.$router.push({ name: 'agency.businesses.index' })
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.businessForm.setErrors(err.response.data.errors)
          }
          this.loadingCreate = false
        })
    },
  },
}
</script>
